
 :root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}


::selection{
    color:#fff;
    background:#5a5278
}
body{
    background-color:#f1f1f1;
    color:#2c2945
}
.loader{
    color:#6bbb7c
}





.btn-primary{
    background:#5a5278;
    color:#fff;
    border-color:#5a5278
}
.btn-info{
    background-color:#007bff;
    color:#fff;
    border-color:#007bff
}
.btn-danger{
    background:#f50d0d;
    color:#fff;
    border-color:#f50d0d
}
.btn-warning{
    background-color:#ffc107;
    border-color:#ffc107
}
.btn-success{
    background-color:#14b905e7;
    border-color:#14b905e7
}





.bg-primary{
    background-color:#5a5278 !important;
    border:transparent
}
.bg-warning{
    background-color:#ffc107 !important;
    border:transparent
}
.bg-info{
    background-color:#007bff !important;
    border:transparent
}
.bg-success{
    background-color:#14b905e7 !important;
    border:transparent
}
.bg-danger{
    background-color:#f50d0d !important;
    border:transparent
}

.bg-blue,.bg-azure,.bg-indigo,.bg-purple,.bg-red,.bg-orange{
    background-color:#007bff !important
}
.bg-pink,.bg-yellow,.bg-lime,.bg-green,.bg-teal,.bg-cyan{
    background-color:#e8769f !important
}
.bg-light-blue,.bg-light-azure,.bg-light-indigo,.bg-light-lime,.bg-light-green,.bg-light-teal{
    background-color:rgba(90,82,120,0.1);
    color:#5a5278
}
.bg-light-pink,.bg-light-red,.bg-light-orange,.bg-light-yellow,.bg-light-cyan{
    background-color:rgba(232,118,159,0.1);
    color:#e8769f
}
.bg-light-purple{
    background-color:rgba(228,189,81,0.1);
    color:#e4bd51
}
.bg-light-gray{
    background-color:rgba(102,106,109,0.1);
    color:#666A6D
}



.tag-info{
    background:#007bff
}
.tag-success{
    background:#14b905e7
}
.tag-danger{
    background:#f50d0d
}
.tag-warning{
    background:#ffc107
}
.tag-primary{
    background:#5a5278
}

.text-info{
    background:#007bff
}
.text-success{
    background:#14b905e7
}
.text-danger{
    background:#f50d0d
}
.text-warning{
    background:#ffc107
}
.text-primary{
    background:#5a5278
}
.text-orange{
    color:#f88400 !important
}



.ribbon .ribbon-box.green{
    background:#14b905e7
}
.ribbon .ribbon-box.green::before{
    border-color:#14b905e7;
    border-right-color:transparent
}

.ribbon .ribbon-box.orange{
    background:#f88400
}
.ribbon .ribbon-box.orange::before{
    border-color:#f88400;
    border-right-color:transparent
}

.ribbon .ribbon-box.blue{
    background:#007bff
}
.ribbon .ribbon-box.blue::before{
    border-color:#007bff;
    border-right-color:transparent
}

.ribbon .ribbon-box.pink{
    background:#ee4784
}
.ribbon .ribbon-box.pink::before{
    border-color:#ee4784;
    border-right-color:transparent
}

.ribbon .ribbon-box.indigo{
    background:#e4bd51
}
.ribbon .ribbon-box.indigo::before{
    border-color:#e4bd51;
    border-right-color:transparent
}

.ribbon .ribbon-box.red{
    background:#f50d0d
}
.ribbon .ribbon-box.red::before{
    border-color:#f50d0d;
    border-right-color:transparent
}

















.mail-star.active,.page-title{
    color:#5a5278 !important
}

.badge-default{
    background:#4e4b6d;
    color:#f1f1f1
}
.badge-primary{
    background-color:#5a5278
}
.badge-success{
    background-color:#5a5278
}



a,.btn-link{
    color:#5a5278
}





.progress-bar{
    background-color:#f50d0d;
    border:transparent
}
.btn-outline-primary{
    color:#5a5278;
    border-color:#5a5278
}
.btn-outline-primary:hover{
    background-color:#5a5278;
    border-color:#5a5278
}
.custom-control-input:checked ~ .custom-control-label::before,.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before,.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before{
    border-color:#5a5278;
    background-color:#5a5278
}
.custom-switch-input:checked ~ .custom-switch-indicator{
    background:#5a5278
}
.selectgroup-input:checked+.selectgroup-button{
    border-color:#5a5278;
    color:#5a5278;
    background:rgba(90,82,120,0.2)
}
.imagecheck-figure:before{
    color:#fa0000 !important
}
.wizard>.steps .current a,.wizard>.steps .current a:hover,.wizard>.steps .current a:active,.wizard>.actions a,.wizard>.actions a:hover{
    background:#5a5278
}
.wizard>.steps .done a,.wizard>.steps .done a:hover{
    background:rgba(90,82,120,0.2);
    color:#5a5278
}
.table th{
    color:#2c2945
}
.table.table-custom td,.table.table-custom th{
    background:#f1f1f1
}
.right_chat li.online .status{
    background:#5a5278
}

.avatar.avatar-blue{
    background-color:rgba(90,82,120,0.1);
    color:#5a5278
}
.avatar.avatar-azure{
    background-color:rgba(90,82,120,0.1);
    color:#5a5278
}
.avatar.avatar-indigo{
    background-color:rgba(90,82,120,0.1);
    color:#5a5278
}
.avatar.avatar-purple{
    background-color:rgba(90,82,120,0.1);
    color:#5a5278
}
.avatar.avatar-pink{
    background-color:rgba(90,82,120,0.1);
    color:#5a5278
}
.avatar.avatar-red{
    background-color:rgba(90,82,120,0.1);
    color:#5a5278
}
.avatar.avatar-orange{
    background-color:rgba(90,82,120,0.1);
    color:#5a5278
}
.avatar.avatar-yellow{
    background-color:rgba(232,118,159,0.1);
    color:#e8769f
}
.avatar.avatar-lime{
    background-color:rgba(232,118,159,0.1);
    color:#e8769f
}
.avatar.avatar-green{
    background-color:rgba(232,118,159,0.1);
    color:#e8769f
}
.avatar.avatar-teal{
    background-color:rgba(232,118,159,0.1);
    color:#e8769f
}
.avatar.avatar-cyan{
    background-color:rgba(232,118,159,0.1);
    color:#e8769f
}

.page-header .right .nav-pills .nav-link{
    color:#2c2945
}
#header_top .brand-logo{
    background:#5a5278
}
.header{
    background:#5a5278
}
.header .nav-tabs .nav-link{
    color:#fff
}
.header .nav-tabs .nav-link.active{
    border-color:#fff
}
.header .dropdown-menu .dropdown-item:hover,.header .dropdown-menu .dropdown-item.active{
    color:#5a5278
}
.page .section-white,.page .section-body{
    background:#f1f1f1
}
#header_top .nav-link{
    color:#2c2945
}
.header_top.dark{
    background-color:#39374e !important
}
.header_top.dark .nav-link{
    color:#f1f1f1 !important
}
.card{
    background-color:#fff
}
.card .card-options a{
    color:#5a5278
}
.card .card-options a.btn{
    color:#fff
}
.card.card-fullscreen{
    background-color:#fff !important
}
.metismenu a{
    color:#2c2945
}
.metismenu a:hover{
    color:#5a5278;
    border-color:#5a5278
}
.metismenu .active>a{
    color:#5a5278;
    border-color:#5a5278
}
.metismenu .active ul .active a{
    color:#5a5278;
    background:transparent
}
.metismenu.grid>li.active>a,.metismenu.grid>li>a:hover{
    background:rgba(90,82,120,0.1);
    color:#5a5278
}
.inbox .detail{
    background:#fff
}
.file_folder a{
    background:#fff;
    border-color:#f1f1f1
}
.auth .auth_left{
    background:#fff
}
.gender_overview{
    background:#f1f1f1
}
.table-calendar-link{
    background:#5a5278
}
.table-calendar-link:hover{
    background:#39374e
}
.table-calendar-link::before{
    background:#39374e
}
.theme_div{
    background:#fff
}






.auth .auth_left{
    background:#39374e
}
.auth .auth_left .card{
    padding:20px;
    right:-150px;
    z-index:99999
}
@media only screen and (max-width: 1024px){
    .auth .auth_left .card{
        right:-50px
    }
}
@media only screen and (max-width: 992px){
    .auth .auth_left .card{
        right:auto
    }
}
.top_dark{
    background-color:#39374e !important
}
.sidebar_dark #left-sidebar{
    background-color:#39374e !important
}
.sidebar_dark #header_top .nav-link{
    color:#f1f1f1 !important
}
.sidebar_dark .sidebar .metismenu .active>a{
    color:#e4bd51
}
.sidebar_dark .sidebar .metismenu .active ul .active a{
    color:#e4bd51
}
.iconcolor #header_top .nav-link{
    color:#e4bd51 !important
}
.gradient .custom-switch-input:checked ~ .custom-switch-indicator{
    background:linear-gradient(45deg, #5a5278, #e8769f) !important
}
.gradient .metismenu.grid>li.active>a,.gradient .metismenu.grid>li>a:hover{
    background:linear-gradient(45deg, #5a5278, #5a5278) !important;
    color:#fff;
    border:0
}
.gradient{
    background:linear-gradient(45deg, #5a5278, #e8769f) !important;
    border:0
}
.gradient .btn-dark{
    background:linear-gradient(45deg, #808488, #333537) !important
}
.gradient .bg-success,.gradient .bg-danger,.gradient .badge-success,.gradient .progress-bar,.gradient .btn-danger{
    background:linear-gradient(45deg, #5a5278, #e8769f) !important
}
.metismenu.grid>li>a{
    border-color:#5a5278
}
 



.nav-tabs .nav-link.active{
    border-color:#5a5278;
    color:#5a5278
}
.form-control:focus{
    box-shadow:0 0 0 0.2rem rgba(90,82,120,0.25)
}
.card,.dropify-wrapper,.btn-primary{
    border:0
}